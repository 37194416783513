import PropTypes from 'prop-types';
import React from 'react'

import Heading from './../../atoms/Heading'
import InputSearch from './../../atoms/InputSearch';
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import Alert from 'molecules/Alert';
import IconArrowLeft from 'atoms/SvgIcons/IconArrowLeft.svg';

const HeaderForModalV2 = ({ className, searchText, setSearchText, children, isCloseButton, isSearchBar, handlerModalClose, onChange, dataAutomation, dataAutomationForSearch, padding, alertMsg, setAlertMsg, onBack }) => {
    return (
        <>
            <div className={`${className} ${padding || 'px-3.5 xs:px-5 sm:px-8'} ${onBack ? '' : 'justify-between'} flex gap-5  items-center min-h-[25px] relative bg-basic-white z-10`}>

                {onBack && <div className={``}>
                    <IconArrowLeft className={`w-6 fill-none stroke-gray-700 hover:stroke-primary1-500 cursor-pointer`} onClick={onBack} dataAutomation='back-button' />
                </div>}

                <Heading type="h3" className='text-16-20 sm:text-18-20 line-clamp-2'>{children}</Heading>

                <div className='flex gap-5 xs:gap-13.5'>
                    {isSearchBar && <InputSearch type='small' className='max-w-200px ' placeholder="Search" searchText={searchText} onChange={onChange} dataAutomation={dataAutomationForSearch} />}
                    {
                        isCloseButton && (
                            <button title='Close' type='reset' onClick={handlerModalClose} className='group p-1' {...(dataAutomation && { 'data-automation': dataAutomation + '-modal-close' })}>
                                <IconClose className="w-4 md:w-6 fill-none stroke-gray-900 group-hover:stroke-primary1-500" />
                            </button>
                        )
                    }
                </div>

            </div>
            {alertMsg?.message && <Alert className='top-15 z-9' type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}
        </>

    )
}

HeaderForModalV2.defaultProps = {
    className: '',
    padding: null,
}

HeaderForModalV2.propTypes = {
    isCloseButton: PropTypes.bool,
    handlerModalClose: PropTypes.func,
    padding: PropTypes.string,
}

export default HeaderForModalV2