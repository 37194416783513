import React from 'react'
import SvgIcon from './SvgIcon';

const List = (props) => {
    const { className, list, iconClass, iconType, iconSize, classList='', mb = 'mb-0.5' } = props;
    return (
        <ul className={`${className} orderList`}>
            {
                list?.map((item, i) => (
                    <li key={i + item?.substring(0, 10)} className={`${classList} ${mb}`}>
                        {iconType && <SvgIcon className={`${iconClass} shrink-0 inline-block mt-0.5 mr-1.5`} type={iconType} size={iconSize} />}
                        {item}
                    </li>
                ))
            }
        </ul>
    )
}

export default List