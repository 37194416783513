import Paragraph from 'atoms/Paragraph';
import React from 'react'
import Loader from 'components/Loader/Loading';
import OTPInput from 'atoms/OTPInput';
import Button from 'atoms/Button';
import DoctorImageCaptionCard from './DoctorImageCaptionCard';

const OtpCard = ({ className = '', bodyClass = '', type, alertMsg, setAlertMsg, heading, description, isLoading, onReSendOtp, setOtpValue, otpValue, onSendOtp, isDisableProceedBtn, setIsDisableProceedBtn }) => {
    return (<div className={`${className} overflow-hidden`}>

        <DoctorImageCaptionCard title={heading} alertMsg={alertMsg} setAlertMsg={setAlertMsg} />
        <div className={`${bodyClass}`}>
            <div className="py-6 xs:py-10 sm:py-12 lg:py-8 mx-auto">

                <Paragraph className="mb-8 lg:mb-12 text-center">{description}</Paragraph>
                {isLoading && <Loader />}

                <OTPInput
                    className="flex justify-center"
                    length={4}
                    separator={<span>-</span>}
                    onChange={(otp) => { setOtpValue(otp) }}
                    otpValue={otpValue}
                />
                <div className={`flex mt-5 ${type === 'forget' ? 'justify-center' : 'justify-between'}`}>
                    <Button variant="Link" size="medium" fontSize='overline' onClick={() => { setOtpValue(''); onReSendOtp(); }} dataAutomation='resend-otp'>Re-send OTP</Button>
                </div>
            </div>

            <div className="flex justify-center">
                <Button
                    className="w-200px"
                    size="medium"
                    variant="Primary"
                    onClick={() => {onSendOtp(otpValue);  setIsDisableProceedBtn(true)}}
                    disabled={isDisableProceedBtn}
                    dataAutomation='otp-proceed'
                >
                    Proceed
                </Button>
            </div>
        </div>
    </div>
    )
}

export default OtpCard