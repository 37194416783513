import React from 'react'
import Portal from './Portal'

const StickyFlyer = ({ className = '', onFlyerClick, title }) => {
    return (
        <div className={`${className} fixed flex w-[95%] max-w-[610px] left-[calc(100%_-_20px)] md:left-[calc(100%_-_28px)] top-1/2 -translate-y-1/2 min-h-[140px] md:min-h-[200px] z-100`}>
            <div onClick={onFlyerClick} data-automation='sticky-slider-open-close-button' className='bg-other-pastel-400 w-5 md:w-7 relative text-basic-white font-bold text-6xl md:text-sm leading-2.5 select-none cursor-pointer shrink-0'>
                <div className='absolute -rotate-90 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap'>{title}</div>
            </div>
        </div>
    )
}

export default Portal(StickyFlyer)