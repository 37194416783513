/* eslint-disable react/react-in-jsx-scope */
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";

const OTPInput = ({
    otpValue,
    length,
    isNumberInput,
    seperator,
    onChange,
    errored,
    shouldAutoFocus,
    className,
}) => {
    return (
        <div className={`${className} w-full`}>
            <OtpInput
                value={otpValue}
                onChange={onChange}
                numInputs={length}
                containerStyle={{
                    fontSize: "16px",
                    color: "#1F1F37",
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                hasErrored={errored}
                inputStyle={{
                    width: 56,
                    height: 56,
                    border: "1px solid #D1D1DB",
                    borderRadius: "8px",
                }}
                isInputNum={isNumberInput}
                focusStyle={{
                    outline: "none",
                    border: "1px solid #24ACDA",
                }}
                errorStyle={{
                    border: "1px solid #DC2020",
                }}
                shouldAutoFocus={shouldAutoFocus}
            />
        </div>
    );
};
export default OTPInput;

OTPInput.propTypes = {
    className: PropTypes.string,
    otpValue: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    seperator: PropTypes.elementType,
    errored: PropTypes.bool,
    isNumberInput: PropTypes.bool,
    shouldAutoFocus: PropTypes.bool,
};
OTPInput.defaultProps = {
    errored: false,
    isNumberInput: true,
    shouldAutoFocus: true,
};
