import React, { Children } from 'react'
import ImageNext from "atoms/ImageNext";
import Text from "atoms/Text";
import Card from 'atoms/Card';
import Alert from 'molecules/Alert';


const DoctorImageCaptionCard = ({ className = '', doctorAvatar, doctorName, title, alertMsg, setAlertMsg, stayDuration = 5 }) => {
    return (
        <div className='relative'>
            <div className={`${className} ${doctorName ? 'h-20' : 'h-13'} rounded-t-2xl relative bg-primary1-100 px-4 py-2 flex items-center gap-4 z-15`}>
                {doctorName && <div className="w-[80px] h-full  bg-gray-100 overflow-hidden relative shrink-0 rounded-lg">
                    <ImageNext className="w-full h-full" src={doctorAvatar} alt={doctorName} layout="fill" objectFit="contain" objectPosition="center" defaultImageFor="avatar" />
                </div>}
                <Text className={`${doctorName ? 'justify-start' : 'justify-center py-1 md:py-2'} text-base md:text-18-28 text-primary1-900 flex w-full`} fontWeight={600}>
                    {doctorName ? doctorName : title}
                </Text>
            </div>
            {alertMsg?.message && <Alert className={`${doctorName ? 'top-20' : 'top-12 md:top-15'} z-5`} type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)} stayDuration={stayDuration} >{alertMsg.message}</Alert>}
        </div>

    );
};

export default DoctorImageCaptionCard;