import React from 'react'

import Avatar from 'atoms/Avatar'

const AuthorDetails = ({ className = '', profileSize = 'w-10 h-10', avatar, authorName,children }) => {
    return (
        <div className={`${className} flex gap-3 items-center`}>
            <div className="flex-shrink-0">
                <Avatar className={profileSize} rounded="full" src={avatar} alt={authorName} />
            </div>
            <div className='flex-grow'>
                {children}
            </div>
        </div>
    )
}

export default AuthorDetails