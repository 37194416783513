import { trackEvent } from "services/moengage.service";

const WidgetEvents = {
    BOOK_APPOINTMENT_CLICK: {
        event_name: "User_Book_Appointment_Click",
        attributes: {
            PAGE: "PAGE",
        }
    },
    SUBMIT_APPOINTMENT_BOOKING: {
        event_name: "User_Submit_Appointment_Booking",
        attributes: {
            PATIENT_LOCATION: "PATIENT_LOCATION",
            PAGE: "PAGE",
            PATIENT_QUERY_PROMPT: "PATIENT_QUERY_PROMPT"
        }
    },
    SUBMIT_APPOINTMENT_BOOKING_CLOSE: {
        event_name: "User_Book_Appointment_Cross_Icon",
        attributes: {
            PAGE: "PAGE"
        }
    },
    VERIFYING_BOOK_APPOINTMENT_OTP: {
        event_name: "User_Verify_Book_Appointment_OTP",
        attributes: {
            BOOKING_ID: "BOOKING_ID",
            PAGE: "PAGE"
        }
    },
    RESEND_BOOK_APPOINTMENT_OTP: {
        event_name: "User_Resend_Book_Appointment_OTP",
        attributes: {
            BOOKING_ID: "BOOKING_ID",
            PAGE: "PAGE"
        }
    },
    CONFIRMING_BOOK_APPOINTMENT: {
        event_name: "User_Confirm_Book_Appointment",
        attributes: {
            PAGE: "PAGE"
        }
    },
    CONFIRMING_BOOK_APPOINTMENT_CLOSE: {
        event_name: "User_Confirm_Book_Appointment_Cross_Icon",
        attributes: {
            PAGE: "PAGE"
        }
    },
    Doctor_Appointment_Click: {
        event_name: "Doctor_Appointment_Click",
        attributes: {
            DOCTOR_ID: "DOCTOR_ID"
        }
    },
    Doctor_Appointment_Cross: {
        event_name: "Doctor_Appointment_Cross",
        attributes: {
            DOCTOR_ID: "DOCTOR_ID"
        }
    },
    Doctor_Appointment_Get_OTP: {
        event_name: "Doctor_Appointment_Get_OTP",
        attributes: {
            MOBILE_NO: "MOBILE_NO"
        }
    },
    Doctor_Appointment_Resend_OTP: {
        event_name: "Doctor_Appointment_Resend_OTP"
    },
    Doctor_Appointment_Submit_Confirm: {
        event_name: "Doctor_Appointment_Submit_Confirm",
        attributes: {
            DOCTOR_ID: "DOCTOR_ID"
        }
    },
    Doctor_Appointment_Submit_Back: {
        eventName: "Doctor_Appointment_Submit_Back",
        attributes: {
            AUTHOR_ID: "AUTHOR_ID"
        }
    },
    Blog_Appointment_Click: {
        event_name: "Blog_Appointment_Click",
        attributes: {
            AUTHOR_ID: "AUTHOR_ID"
        }
    },
    Blog_Appointment_Cross: {
        event_name: "Blog_Appointment_Cross",
        attributes: {
            AUTHOR_ID: "AUTHOR_ID"
        }
    },
    Blog_Appointment_Get_OTP: {
        event_name: "Blog_Appointment_Get_OTP",
        attributes: {
            MOBILE_NO: "MOBILE_NO"
        }
    },
    Blog_Appointment_Resend_OTP: {
        event_name: "Blog_Appointment_Resend_OTP"
    },
    Blog_Appointment_Submit_Confirm: {
        event_name: "Blog_Appointment_Submit_Confirm",
        attributes: {
            AUTHOR_ID: "AUTHOR_ID"
        }
    },
    Blog_Appointment_Submit_Back: {
        eventName: "Blog_Appointment_Submit_Back"
    }
};

const destroySession = () => {
    try {
        window.Moengage.destroy_session()
    }
    catch (err) {
        console.log(err);
    }
}

export default WidgetEvents;

export function onClickBookAppointment(callback = () => { }, attributes = {}, trackEventFor) {
    if (trackEventFor == "doctor") {
        trackEvent(WidgetEvents.Doctor_Appointment_Click.event_name, attributes?.doctor);
    } else {
        trackEvent(WidgetEvents.Blog_Appointment_Click.event_name, attributes?.blogs);
    }
    callback();
};
export function onSubmitBookingAppointmentDetailsClose(callback = () => { }, attributes = {}, trackEventFor) {
    if (trackEventFor == "doctor") {
        trackEvent(WidgetEvents.Doctor_Appointment_Cross.event_name, attributes?.doctor);
    } else {
        trackEvent(WidgetEvents.Blog_Appointment_Cross.event_name, attributes?.blogs);
    }

    callback();
}
export function onSubmitBookingAppointmentDetails(callback = () => { }, attributes = {}, trackEventFor) {
    if (trackEventFor == "doctor") {
        trackEvent(WidgetEvents.Doctor_Appointment_Get_OTP.event_name, attributes?.doctor);
    } else {
        trackEvent(WidgetEvents.Blog_Appointment_Get_OTP.event_name, attributes?.blogs);
    }
    callback();
};

export function onResendingBookingAppointmentOtp(callback = () => { }, attributes = {}, trackEventFor) {
    if (trackEventFor == "doctor") {
        trackEvent(WidgetEvents.Doctor_Appointment_Resend_OTP.event_name, {});
    } else {
        trackEvent(WidgetEvents.Blog_Appointment_Resend_OTP.event_name, {});
    }
    callback();
};
export function onVerifyingBookingAppointmentOtp(callback = () => { }, attributes = {}, trackEventFor) {
    trackEvent(WidgetEvents.Doctor_Appointment_Submit_Confirm.event_name, attributes?.doctor);
    if (trackEventFor == "doctor") {
        trackEvent(WidgetEvents.Doctor_Appointment_Submit_Confirm.event_name, attributes?.doctor);
    } else {
        trackEvent(WidgetEvents.Blog_Appointment_Submit_Confirm.event_name, attributes?.blogs);
    }
    callback();
};
export function onConfirmingBookingAppointmentClose(callback = () => { }, attributes = {}, trackEventFor) {
    if (trackEventFor == "doctor") {
        trackEvent(WidgetEvents.Doctor_Appointment_Submit_Back.event_name, attributes?.doctor);
    } else {
        trackEvent(WidgetEvents.Doctor_Appointment_Submit_Back.event_name, attributes?.blogs);
    }
    callback();
}

export function onConfirmingBookingAppointment(callback = () => { }, attributes = {}, trackEventFor) {
    callback();
};
